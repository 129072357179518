import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { useFileObjectsRefetch } from 'api';
import { FileObjectFilters, FileObjectGrid, FileObjectPreview, FileObjectSearch, FileUploadDroppable, } from 'components/custom';
import AddObjectSplitButton from 'components/custom/AddObjectSplitButton';
import { getDefaultFilters } from 'components/custom/FileObjectFilters';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DragAndDropOperationNames } from 'utils/drag-and-drop';
import { setTitle } from 'utils/head';
const Data = () => {
    const { t } = useTranslation('data');
    const [searchQuery, setSearchQuery] = React.useState('');
    const [error, setError] = React.useState(undefined);
    const [filterQueryOptions, setFilterQueryOptions] = React.useState(getDefaultFilters());
    const [fileObject, setFileObject] = React.useState(undefined);
    const refetchFileObjects = useFileObjectsRefetch();
    useEffect(() => {
        setTitle(t('head.title'));
    }, [t]);
    const id = React.useId();
    const closePreview = () => {
        setFileObject(undefined);
    };
    const onDeleted = (ids) => {
        if (fileObject && ids.includes(fileObject.id)) {
            closePreview();
        }
    };
    return (_jsxs(Stack, { spacing: 2, height: "100%", children: [_jsx(FileObjectSearch, { onQueryChange: setSearchQuery, error: error }), _jsx(FileObjectFilters, { onFilterChange: setFilterQueryOptions }), _jsx(FileObjectPreview, { fileObject: fileObject, onClose: closePreview }), _jsx(Stack, { direction: "row", spacing: 2, justifyContent: "end", children: _jsx(AddObjectSplitButton, { refetchFileObjects: refetchFileObjects }) }), _jsx(FileUploadDroppable, { droppableId: id, operationName: DragAndDropOperationNames.UPLOAD_FILE, onUploadFinally: refetchFileObjects, outlinePosition: "outside", children: _jsx(FileObjectGrid, { filter: filterQueryOptions, searchQuery: searchQuery, onError: setError, onNameClick: setFileObject, onDeleted: onDeleted }) })] }));
};
export default Data;
