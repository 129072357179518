import { jsx as _jsx } from "react/jsx-runtime";
import { Api } from 'api';
import { DefaultLayout, SettingsLayout, SidebarLayout, TimetableLayout, } from 'layouts';
import Activation from 'pages/auth/activation';
import Error from 'pages/auth/error';
import Login from 'pages/auth/login';
import Logout from 'pages/auth/logout';
import Recovery from 'pages/auth/recovery';
import Registration from 'pages/auth/registration';
import RegistrationSuccessful from 'pages/auth/registration-successful';
import Verification from 'pages/auth/verification';
import Data from 'pages/data';
import Home from 'pages/home';
import Lesson from 'pages/lesson';
import PageNotFound from 'pages/page-not-found';
import AccountData from 'pages/settings/account-data';
import PlansAndPayments from 'pages/settings/plans-and-payments';
import Courses from 'pages/settings/timetable/courses';
import Lessons from 'pages/settings/timetable/lessons';
import SchoolYear from 'pages/settings/timetable/school-years';
import Vacation from 'pages/settings/timetable/vacation';
import AccountTermination from 'pages/termination';
import * as link from 'utils/links';
import { Updater } from './Updater';
import SharedLayout from 'layouts/Shared';
import SharedLesson from 'pages/lesson/shared';
/* eslint-disable sort-keys -- Routes */
export const getRoutes = () => [
    {
        element: _jsx(Updater, {}),
        children: [
            {
                element: _jsx(Api, {}),
                children: [
                    {
                        element: _jsx(DefaultLayout, {}),
                        children: [
                            {
                                path: link.HOME,
                                element: _jsx(Home, {}),
                            },
                            {
                                path: link.LESSON,
                                element: _jsx(Lesson, {}),
                            },
                            {
                                path: link.DATA,
                                element: _jsx(Data, {}),
                            },
                            {
                                path: link.SETTINGS,
                                element: _jsx(SettingsLayout, {}),
                                children: [
                                    {
                                        path: link.ACCOUNT_DATA,
                                        element: _jsx(AccountData, {}),
                                    },
                                    {
                                        path: link.PLANS_AND_PAYMENTS,
                                        element: _jsx(PlansAndPayments, {}),
                                    },
                                    {
                                        path: link.TIMETABLE,
                                        element: _jsx(TimetableLayout, {}),
                                        children: [
                                            {
                                                path: link.SCHOOL_YEARS,
                                                element: _jsx(SchoolYear, {}),
                                            },
                                            {
                                                path: link.VACATION,
                                                element: _jsx(Vacation, {}),
                                            },
                                            {
                                                path: link.COURSES,
                                                element: _jsx(Courses, {}),
                                            },
                                            {
                                                path: link.LESSONS,
                                                element: _jsx(Lessons, {}),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        element: _jsx(SidebarLayout, { variant: "login" }),
                        children: [
                            {
                                path: link.ACTIVATION,
                                element: _jsx(Activation, {}),
                            },
                            {
                                path: link.AUTH_ERROR,
                                element: _jsx(Error, {}),
                            },
                            {
                                path: link.REGISTRATION,
                                element: _jsx(Registration, {}),
                            },
                            {
                                path: link.REGISTRATION_SUCCESSFUL,
                                element: _jsx(RegistrationSuccessful, {}),
                            },
                            {
                                path: link.EMAIL_VERIFICATION,
                                element: _jsx(Verification, {}),
                            },
                            {
                                path: link.ACCOUNT_TERMINATION,
                                element: _jsx(AccountTermination, {}),
                            },
                        ],
                    },
                    {
                        element: _jsx(SidebarLayout, { variant: "registration" }),
                        children: [
                            {
                                path: link.LOGIN,
                                element: _jsx(Login, {}),
                            },
                            {
                                path: link.LOGOUT,
                                element: _jsx(Logout, {}),
                            },
                            {
                                path: link.PASSWORD_RECOVERY,
                                element: _jsx(Recovery, {}),
                            },
                        ],
                    },
                    {
                        element: _jsx(SharedLayout, {}),
                        children: [{ path: link.SHARED_LESSON, element: _jsx(SharedLesson, {}) }],
                    },
                ],
            },
            {
                path: '*',
                element: _jsx(PageNotFound, {}),
            },
        ],
    },
];
