import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { eachDayOfInterval, getDay, nextFriday, nextMonday } from 'date-fns';
import { useMemo } from 'react';
import { convertIntervalToSlot, FIRST_DAY_OF_THE_WEEK, getSeqArray, HOURS_PER_DAY, LAST_DAY_OF_THE_WEEK, } from 'utils/date';
import CourseCell from './CourseCell';
import DayOfTheWeekCell from './DayOfTheWeekCell';
import HourInDayCell from './HourInDayCell';
export const convertLessonsToSlots = (lessons) => {
    return lessons.map((lesson) => {
        const { id, startDate, endDate, course: { name, color }, } = lesson;
        return {
            color,
            name,
            slotId: id,
            ...convertIntervalToSlot({
                end: endDate,
                start: startDate,
            }),
        };
    });
};
const WeeklySchedule = ({ data, dateOfMonday = null, onDeleteCourseSuccess, onPlanCourseSuccess, isPlanCourseView = false, onHourLengthChange, onlyOneDay = false, }) => {
    const daysOfTheWeek = useMemo(() => {
        const start = dateOfMonday !== null && dateOfMonday !== void 0 ? dateOfMonday : nextMonday(Date.now());
        const end = onlyOneDay ? start : nextFriday(start);
        return eachDayOfInterval({
            end,
            start,
        }).map((date, index) => (_jsx(DayOfTheWeekCell, { date: dateOfMonday && date, day: index + 1 }, index)));
    }, [dateOfMonday, onlyOneDay]);
    const hoursInDay = useMemo(() => getSeqArray(HOURS_PER_DAY).map((value, index) => (_jsx(HourInDayCell, { slotNumber: value }, index))), []);
    const courses = useMemo(() => {
        var _a;
        const result = [];
        const firstDay = onlyOneDay
            ? getDay(dateOfMonday !== null && dateOfMonday !== void 0 ? dateOfMonday : nextMonday(Date.now()))
            : FIRST_DAY_OF_THE_WEEK;
        const lastDay = onlyOneDay ? firstDay : LAST_DAY_OF_THE_WEEK;
        for (let day = firstDay; day <= lastDay; day++) {
            for (let hour = 1; hour <= HOURS_PER_DAY;) {
                const course = data.find((c) => c.weekday === day && c.hourInDay === hour);
                const emptyNextCell = hour < HOURS_PER_DAY
                    ? !data.find((currentCourse) => currentCourse.weekday === day &&
                        currentCourse.hourInDay === hour + 1)
                    : false;
                result.push(_jsx(CourseCell, { course: course, day: onlyOneDay ? 1 : day, hour: hour, linkToLesson: !!dateOfMonday, isPlanCourseView: isPlanCourseView, onPlanCourseSuccess: onPlanCourseSuccess, onDeleteCourseSuccess: onDeleteCourseSuccess, onHourLengthChange: onHourLengthChange, emptyNextCell: emptyNextCell }, `${day}-${hour}`));
                hour += (_a = course === null || course === void 0 ? void 0 : course.lengthHours) !== null && _a !== void 0 ? _a : 1;
            }
        }
        return result;
    }, [
        data,
        dateOfMonday,
        onlyOneDay,
        isPlanCourseView,
        onDeleteCourseSuccess,
        onPlanCourseSuccess,
        onHourLengthChange,
    ]);
    return (_jsxs(Box, { sx: {
            display: 'grid',
            gridTemplateColumns: `minmax(50px, 75px) repeat(${onlyOneDay ? 1 : LAST_DAY_OF_THE_WEEK}, minmax(0, 1fr))`,
            gridTemplateRows: `repeat(${HOURS_PER_DAY + 1}, minmax(50px, auto))`,
        }, role: "grid", children: [daysOfTheWeek, hoursInDay, courses] }));
};
export default WeeklySchedule;
