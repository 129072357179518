import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import { useTimetablesQueryApi } from 'api';
import { Button } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { setTitle } from 'utils/head';
import HolidaysSection from './HolidaysSection';
import SchoolYearSection from './SchoolYearSection';
import TimetableValidityPeriodsSection from './TimetableValidityPeriodsSection';
import VacationsSection from './VacationsSection';
const Vacation = () => {
    const { t: headTranslate } = useTranslation('settings', {
        keyPrefix: 'head',
    });
    React.useEffect(() => {
        setTitle(headTranslate('title.vacations'));
    }, [headTranslate]);
    const { t: commonTranslation } = useTranslation('common');
    const { handlePrev, handleNext } = useOutletContext();
    const { schoolYearId = '' } = useParams();
    const timetableStatus = useTimetablesQueryApi({ schoolYearId });
    return (_jsxs(Stack, { spacing: 2, flexGrow: "1", children: [_jsxs(Stack, { spacing: 2, flexGrow: "1", children: [_jsx(SchoolYearSection, {}), _jsx(Divider, {}), _jsx(TimetableValidityPeriodsSection, { timetableStatus: timetableStatus }), _jsx(Divider, {}), _jsx(VacationsSection, {}), _jsx(Divider, {}), _jsx(HolidaysSection, {}), _jsx(Divider, {})] }), _jsxs(Stack, { direction: "row", spacing: 2, justifyContent: "end", children: [_jsx(Button, { variant: "contained", color: "error", onClick: handlePrev(schoolYearId), startIcon: _jsx(KeyboardDoubleArrowLeft, {}), "data-testid": "back", children: commonTranslation('body.button.back') }), _jsx(Button, { variant: "contained", color: "success", disabled: !timetableStatus.data.length, onClick: handleNext(schoolYearId), endIcon: _jsx(KeyboardDoubleArrowRight, {}), "data-testid": "next", children: commonTranslation('body.button.next') })] })] }));
};
export default Vacation;
