import { jsx as _jsx } from "react/jsx-runtime";
import { getDeleteTimetableMutationOptions, isLoading, useDeleteTimetableMutation, } from 'api';
import { DeletionConfirmationDialog, LoadingWrapper } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const DeleteValidityPeriodDialog = (props) => {
    const { validityPeriodToDelete, setValidityPeriodToDelete } = props;
    const { t } = useTranslation('settings', {
        keyPrefix: 'body.timetable.steps.vacation.cmp.DeleteValidityPeriodDialog',
    });
    const [deleteTimetable, deleteTimetableStatus] = useDeleteTimetableMutation();
    const handleDelete = () => {
        if (validityPeriodToDelete === null || validityPeriodToDelete === void 0 ? void 0 : validityPeriodToDelete.id) {
            void deleteTimetable(getDeleteTimetableMutationOptions(validityPeriodToDelete.id)).then(() => {
                setValidityPeriodToDelete(null);
                enqueueSnackbar(t('successMessage'));
            });
        }
    };
    return (_jsx(DeletionConfirmationDialog, { open: !!(validityPeriodToDelete === null || validityPeriodToDelete === void 0 ? void 0 : validityPeriodToDelete.id), onClose: () => {
            setValidityPeriodToDelete(null);
        }, titleProps: {
            text: t('title'),
        }, cancelButtonProps: {
            onClick: () => {
                setValidityPeriodToDelete(null);
            },
        }, confirmButtonProps: {
            children: t('actions.delete'),
            disabled: isLoading(deleteTimetableStatus),
            onClick: handleDelete,
        }, children: _jsx(LoadingWrapper, { mutation: deleteTimetableStatus, children: t('text', {
                name: validityPeriodToDelete === null || validityPeriodToDelete === void 0 ? void 0 : validityPeriodToDelete.name,
            }) }) }));
};
export default DeleteValidityPeriodDialog;
