import { jsx as _jsx } from "react/jsx-runtime";
import { NodeInputButton } from './NodeInputButton';
import { NodeInputCheckbox } from './NodeInputCheckbox';
import { NodeInputDefault } from './NodeInputDefault';
import { NodeInputHidden } from './NodeInputHidden';
import { NodeInputSubmit } from './NodeInputSubmit';
export const NodeInput = (props) => {
    const { attributes } = props;
    switch (attributes.type) {
        case 'hidden':
            // Render a hidden input field
            return _jsx(NodeInputHidden, { ...props });
        case 'checkbox':
            /*
             * Render a checkbox. We have one hidden element which is the real value (true/false), and one
             * display element which is the toggle value (true)!
             */
            return _jsx(NodeInputCheckbox, { ...props });
        case 'button':
            // Render a button
            return _jsx(NodeInputButton, { ...props });
        case 'submit':
            // Render the submit button
            return _jsx(NodeInputSubmit, { ...props });
        default:
            // Render a generic text input field.
            return _jsx(NodeInputDefault, { ...props });
    }
};
