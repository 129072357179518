import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Link as MuiLink } from '@mui/material';
import React from 'react';
import { getObjectRedirectUrl } from 'utils/external-links';
import { isPreviewSupported } from 'utils/file';
import { getFileIcon } from 'utils/file-icons';
import { isEqualPureMimeType, MimeTypes } from 'utils/mime-types';
const FileObjectNameCell = ({ fileId, mimeType, name, onClick, sharingToken, }) => {
    const Icon = getFileIcon(mimeType);
    const NameCmp = React.useMemo(() => {
        if (isEqualPureMimeType(mimeType, MimeTypes.URL)) {
            return (_jsx(MuiLink, { href: getObjectRedirectUrl(fileId, sharingToken), rel: "noopener noreferrer", target: "_blank", children: name }));
        }
        if (onClick && isPreviewSupported(mimeType)) {
            return (_jsx(Box, { component: "span", sx: { cursor: 'pointer', textDecoration: 'underline' }, onClick: () => {
                    onClick(fileId);
                }, children: name }));
        }
        return name;
    }, [mimeType, name, onClick, fileId]);
    return (_jsxs(_Fragment, { children: [_jsx(Icon, {}), _jsx(Box, { title: name, sx: { ml: 1, overflow: 'hidden', textOverflow: 'ellipsis' }, children: NameCmp })] }));
};
export default FileObjectNameCell;
