import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CalendarViewWeek, Delete, Download, Edit, Forward, More, MoreVert, OpenInNew, ViewSidebar, } from '@mui/icons-material';
import { Box, IconButton, Link as MuiLink, Menu } from '@mui/material';
import { GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid';
import { ConfirmationDialog, DataGrid, DeletionConfirmationDialog, FileObjectNameEditorDialog, GridActionsCellLinkItem, GridHeaderActionsCellItem, LessonAssignmentDialog, TagManagementDialog, } from 'components/custom';
import { useWidgetContext, Widget } from 'components/custom/WidgetProvider';
import { concat, isArray } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getObjectRedirectUrl } from 'utils/external-links';
import { getMultiFileDownloadUrl } from 'utils/file-download';
import { log } from 'utils/logger';
import { isEqualPureMimeType, MimeTypes } from 'utils/mime-types';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["ASSIGN"] = "assign";
    ActionTypes["DELETE"] = "delete";
    ActionTypes["MOVE_TO_NEXT_LESSON"] = "moveToNextLesson";
    ActionTypes["RENAME"] = "rename";
    ActionTypes["TAG"] = "tag";
})(ActionTypes || (ActionTypes = {}));
const FileObjectDataGrid = (props) => {
    const componentId = React.useId();
    const apiRef = useGridApiRef();
    const { t } = useTranslation(concat([], props.translationNamespace, 'object'), {
        keyPrefix: 'cmp.FileObjectDataGrid',
        nsMode: 'fallback',
    });
    const { setWidget } = useWidgetContext();
    const [action, setAction] = React.useState(undefined);
    const [openMenu, setOpenMenu] = React.useState(undefined);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const clearAction = () => {
        setAction(undefined);
    };
    const isSingleItem = (item) => !isArray(item);
    const deselectAll = () => {
        var _a;
        setRowSelectionModel([]);
        (_a = props.onSelectionChange) === null || _a === void 0 ? void 0 : _a.call(props, {
            count: 0,
            deselectAll,
            getSelected: () => [],
        });
    };
    const deleteObjects = (fileObjects) => {
        props.onDelete(fileObjects, {
            deselectAll,
        });
        clearAction();
    };
    const renameObject = (fileObject) => {
        props.onRename(fileObject);
        clearAction();
    };
    const onMenuButtonClick = (event) => {
        setOpenMenu(event.currentTarget);
    };
    const menuClose = () => {
        setOpenMenu(undefined);
    };
    const onRowSelectionModelChange = (newRowSelectionModel) => {
        var _a;
        setRowSelectionModel(newRowSelectionModel);
        (_a = props.onSelectionChange) === null || _a === void 0 ? void 0 : _a.call(props, {
            count: newRowSelectionModel.length,
            deselectAll,
            getSelected: () => {
                return props.rows.filter((file) => {
                    return newRowSelectionModel.includes(file.id);
                });
            },
        });
    };
    React.useEffect(() => {
        if (props.actionRef) {
            props.actionRef.current = {
                setAction,
            };
        }
    }, [props.actionRef]);
    const columns = [...props.columns];
    if (!props.disableActions) {
        columns.push({
            align: 'right',
            field: 'actions',
            getActions: (params) => {
                var _a;
                const items = [];
                const selectedRows = apiRef.current.getSelectedRows();
                if (selectedRows.size) {
                    return [];
                }
                if (isEqualPureMimeType(params.row.mimeType, MimeTypes.URL)) {
                    items.push(_jsx(GridActionsCellLinkItem, { icon: _jsx(OpenInNew, {}), label: t('action.open'), href: getObjectRedirectUrl(params.row.id), showInMenu: true }, "open"));
                }
                else {
                    items.push(_jsx(GridActionsCellLinkItem, { icon: _jsx(Download, {}), label: t('action.download'), href: params.row.downloadUrl, download: params.row.name, showInMenu: true }, "download"));
                }
                items.push(_jsx(GridActionsCellItem, { icon: _jsx(ViewSidebar, {}), label: t('action.showInWidget'), onClick: () => {
                        setWidget({
                            active: Widget.FILES,
                            params: {
                                fileId: params.row.id,
                                rowIndex: props.rows.findIndex((row) => row.id === params.row.id),
                            },
                        });
                    }, showInMenu: true }, "showInWidget"), _jsx(GridActionsCellItem, { icon: _jsx(CalendarViewWeek, {}), label: t('action.assignToLesson'), onClick: () => {
                        setAction({
                            item: params.row,
                            type: ActionTypes.ASSIGN,
                        });
                    }, showInMenu: true }, "assignToLesson"));
                if (props.onMoveToNextLesson) {
                    items.push(_jsx(GridActionsCellItem, { icon: _jsx(Forward, {}), label: t('action.moveToNextLesson'), disabled: props.disableMoveToNextLesson, onClick: () => {
                            setAction({
                                item: params.row,
                                type: ActionTypes.MOVE_TO_NEXT_LESSON,
                            });
                        }, showInMenu: true }, "moveToNextLesson"));
                }
                items.push(_jsx(GridActionsCellItem, { icon: _jsx(Delete, {}), label: t('action.delete'), onClick: () => {
                        setAction({
                            item: params.row,
                            type: ActionTypes.DELETE,
                        });
                    }, showInMenu: true }, "delete"), _jsx(GridActionsCellItem, { icon: _jsx(Edit, {}), label: t('action.rename'), onClick: () => {
                        setAction({
                            item: params.row,
                            type: ActionTypes.RENAME,
                        });
                    }, showInMenu: true }, "edit"), _jsx(GridActionsCellItem, { icon: _jsx(More, {}), label: t('action.editTags'), onClick: () => {
                        setAction({
                            item: params.row,
                            type: ActionTypes.TAG,
                        });
                    }, showInMenu: true }, "editTags"));
                const customItems = (_a = props.getActions) === null || _a === void 0 ? void 0 : _a.call(props, params);
                if (customItems && customItems.length > 0) {
                    items.push(...customItems);
                }
                return items;
            },
            headerAlign: 'right',
            renderHeader: () => {
                const menuId = `${componentId}-multiple-items-menu}`;
                const buttonId = `${componentId}-multiple-items-button}`;
                const selectedRows = apiRef.current.getSelectedRows();
                if (selectedRows.size < 1) {
                    return null;
                }
                const selectedFileObjects = [...selectedRows]
                    .map(([rowId]) => props.rows.find((fileObject) => fileObject.id === rowId))
                    .filter((row) => !!row);
                return (_jsxs(_Fragment, { children: [_jsx(IconButton, { id: buttonId, onClick: onMenuButtonClick, "aria-controls": openMenu ? menuId : undefined, "aria-haspopup": "true", "aria-expanded": openMenu ? 'true' : undefined, sx: {
                                '&:hover': {
                                    backgroundColor: (theme) => theme.palette.secondary.main,
                                },
                            }, children: _jsx(MoreVert, {}) }), _jsxs(Menu, { id: menuId, open: !!openMenu, "aria-labelledby": buttonId, anchorEl: openMenu, onClose: menuClose, children: [_jsx(GridHeaderActionsCellItem, { icon: _jsx(Download, {}), label: t('action.download'), LinkComponent: MuiLink, href: getMultiFileDownloadUrl(selectedFileObjects) }, "downloadSelected"), props.onMoveToNextLesson && (_jsx(GridHeaderActionsCellItem, { icon: _jsx(Forward, {}), label: t('action.moveToNextLesson'), disabled: props.disableMoveToNextLesson, onClick: () => {
                                        menuClose();
                                        setAction({
                                            item: selectedFileObjects,
                                            type: ActionTypes.MOVE_TO_NEXT_LESSON,
                                        });
                                    } }, "moveToNextLesson")), _jsx(GridHeaderActionsCellItem, { icon: _jsx(Delete, {}), label: t('action.delete'), onClick: () => {
                                        menuClose();
                                        setAction({
                                            item: selectedFileObjects,
                                            type: ActionTypes.DELETE,
                                        });
                                    } }, "deleteSelected")] })] }));
            },
            sortable: false,
            type: 'actions',
            width: 30,
        });
    }
    return (_jsxs(Box, { sx: { display: 'grid', height: '100%', width: '100%' }, children: [_jsx(DataGrid, { apiRef: apiRef, columns: columns, "data-testid": props['data-testid'], localeText: { noRowsLabel: t('noRowsLabel') }, paginationMode: "server", rowCount: props.totalCount, rows: props.rows, loading: props.loading, checkboxSelection: true, rowSelectionModel: rowSelectionModel, onRowSelectionModelChange: onRowSelectionModelChange, onPaginationModelChange: props.onPaginationModelChange, onSortModelChange: props.onSortModelChange, sortingMode: "server" }), _jsx(DeletionConfirmationDialog, { open: (action === null || action === void 0 ? void 0 : action.type) === ActionTypes.DELETE, onClose: clearAction, titleProps: {
                    text: isSingleItem(action === null || action === void 0 ? void 0 : action.item)
                        ? t('dialogs.deleteSingle.title')
                        : t('dialogs.deleteMultiple.title'),
                }, cancelButtonProps: {
                    onClick: clearAction,
                }, confirmButtonProps: {
                    children: isSingleItem(action === null || action === void 0 ? void 0 : action.item)
                        ? t('dialogs.deleteSingle.actions.delete')
                        : t('dialogs.deleteMultiple.actions.delete'),
                    onClick: () => {
                        if (action) {
                            deleteObjects(action.item);
                        }
                        else {
                            log('No action. Wrong `open` condition?');
                        }
                    },
                }, children: isSingleItem(action === null || action === void 0 ? void 0 : action.item)
                    ? t('dialogs.deleteSingle.text', { name: action === null || action === void 0 ? void 0 : action.item.name })
                    : t('dialogs.deleteMultiple.text') }), _jsx(FileObjectNameEditorDialog, { label: t('dialogs.rename.fieldName'), value: action && isSingleItem(action.item) ? action.item.name : '', onRename: (value) => {
                    if (action && isSingleItem(action.item)) {
                        const fileObject = action.item;
                        renameObject({
                            ...fileObject,
                            name: value,
                        });
                    }
                    else {
                        log('No action. Wrong `open` condition?');
                    }
                }, cancelButtonProps: { onClick: clearAction }, confirmButtonProps: {}, onClose: clearAction, titleProps: {
                    text: t('dialogs.rename.title'),
                }, open: (action === null || action === void 0 ? void 0 : action.type) === ActionTypes.RENAME }), _jsx(TagManagementDialog, { objectToEditTags: action && isSingleItem(action.item) ? action.item : {}, open: (action === null || action === void 0 ? void 0 : action.type) === ActionTypes.TAG, onClose: clearAction, translationNamespace: "object" }), _jsx(LessonAssignmentDialog, { objectId: action && isSingleItem(action.item) ? action.item.id : undefined, open: (action === null || action === void 0 ? void 0 : action.type) === ActionTypes.ASSIGN, onClose: clearAction }), _jsx(ConfirmationDialog, { open: (action === null || action === void 0 ? void 0 : action.type) === ActionTypes.MOVE_TO_NEXT_LESSON, onClose: clearAction, titleProps: {
                    Icon: Forward,
                    text: isSingleItem(action === null || action === void 0 ? void 0 : action.item)
                        ? t('dialogs.moveToNextLessonSingle.title')
                        : t('dialogs.moveToNextLessonMultiple.title'),
                }, cancelButtonProps: {
                    onClick: clearAction,
                }, confirmButtonProps: {
                    children: isSingleItem(action === null || action === void 0 ? void 0 : action.item)
                        ? t('dialogs.moveToNextLessonSingle.actions.moveToNextLesson')
                        : t('dialogs.moveToNextLessonMultiple.actions.moveToNextLesson'),
                    onClick: () => {
                        if (action && props.onMoveToNextLesson) {
                            props.onMoveToNextLesson(action.item);
                            clearAction();
                        }
                    },
                }, children: isSingleItem(action === null || action === void 0 ? void 0 : action.item)
                    ? t('dialogs.moveToNextLessonSingle.text', {
                        name: action === null || action === void 0 ? void 0 : action.item.name,
                    })
                    : t('dialogs.moveToNextLessonMultiple.text') })] }));
};
export default FileObjectDataGrid;
