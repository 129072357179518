import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Stepper } from 'components/custom';
import { CalendarToday, CalendarViewWeek, School, WbSunny, } from '@mui/icons-material';
import { getCoursesUrl, getVacationUrl, getLessonsUrl, SCHOOL_YEARS, } from 'utils/links';
import { getLocationHash, setLocationHash } from 'utils/routing';
const Layout = () => {
    const { t } = useTranslation('settings', {
        keyPrefix: 'body.timetable.stepNames',
    });
    const steps = React.useMemo(() => [
        {
            Icon: CalendarToday,
            label: t('schoolYear'),
            urlGetter: () => SCHOOL_YEARS,
        },
        {
            Icon: WbSunny,
            label: t('vacation'),
            urlGetter: getVacationUrl,
        },
        {
            Icon: School,
            label: t('courses'),
            urlGetter: getCoursesUrl,
        },
        {
            Icon: CalendarViewWeek,
            label: t('hours'),
            urlGetter: getLessonsUrl,
        },
    ], [t]);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { schoolYearId: schoolYearIdParam = getLocationHash() } = useParams();
    const [activeStep, setActiveStep] = React.useState(0);
    React.useEffect(() => {
        const nextActiveStep = steps.findIndex((step) => step.urlGetter(schoolYearIdParam) === pathname);
        setActiveStep(nextActiveStep);
    }, [pathname, schoolYearIdParam, steps]);
    const goToStep = (schoolYearId, step) => {
        // Workaround for React Router v6 issue: https://github.com/remix-run/react-router/discussions/9851
        if (activeStep === 0 && step === 1) {
            setLocationHash(schoolYearId);
        }
        navigate(`${steps[step].urlGetter(schoolYearId)}#${schoolYearId}`);
    };
    const handlePrev = (schoolYearId) => () => {
        goToStep(schoolYearId, activeStep - 1);
    };
    const handleNext = (schoolYearId) => () => {
        goToStep(schoolYearId, activeStep + 1);
    };
    return (_jsxs(Stack, { spacing: 4, flexGrow: "1", children: [_jsx(Stepper, { steps: steps, activeStep: activeStep }), _jsx(Stack, { flexGrow: "1", children: _jsx(Outlet, { context: { handleNext, handlePrev } }) })] }));
};
export default Layout;
