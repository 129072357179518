import { ExtendedObjectsDocument, useLessonQuery, useLessonsQuery, useObjectQuery, } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
import { getApiStringFromDate, getDateFromApiString } from 'utils/date';
export const useLessonsQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useLessonsQuery(getBaseOptions(typeof variables === 'object'
        ? {
            from: getApiStringFromDate(variables.from),
            to: getApiStringFromDate(variables.to),
        }
        : variables, options));
    const lessons = ((_a = data === null || data === void 0 ? void 0 : data.lessons) !== null && _a !== void 0 ? _a : []).map((lesson) => ({
        ...lesson,
        endDate: getDateFromApiString(lesson.endDateTime),
        startDate: getDateFromApiString(lesson.startDateTime),
    }));
    return { ...rest, data: lessons };
};
export const useObjectLessonsQueryApi = (variables, options) => {
    const { data, ...rest } = useObjectQuery(getBaseOptions(variables, options));
    if (!(data === null || data === void 0 ? void 0 : data.object)) {
        return { ...rest, data: undefined };
    }
    const lessons = data.object.lessons.map((lesson) => ({
        ...lesson,
        endDate: getDateFromApiString(lesson.endDateTime),
        startDate: getDateFromApiString(lesson.startDateTime),
    }));
    const object = {
        id: data.object.id,
        mimeType: data.object.mimeType,
        name: data.object.currentVersion.name,
        lessons,
    };
    return { ...rest, data: object };
};
export const useLessonQueryApi = (variables, options) => {
    var _a, _b, _c, _d;
    const { data, ...rest } = useLessonQuery(getBaseOptions(variables, options));
    if (data === null || data === void 0 ? void 0 : data.lesson) {
        const { lesson } = data;
        return {
            ...rest,
            data: {
                courseColor: lesson.course.color,
                courseName: lesson.course.name,
                educationalYearName: lesson.course.educationalYear.name,
                endDate: getDateFromApiString(lesson.endDateTime),
                homework: lesson.homework,
                id: lesson.id,
                nextLessonId: (_a = lesson.nextLesson) === null || _a === void 0 ? void 0 : _a.id,
                notes: lesson.notes,
                previousHomework: (_c = (_b = lesson.previousLesson) === null || _b === void 0 ? void 0 : _b.homework) !== null && _c !== void 0 ? _c : '',
                previousLessonId: (_d = lesson.previousLesson) === null || _d === void 0 ? void 0 : _d.id,
                sharingEndDateTime: lesson.sharingEndDateTime
                    ? getDateFromApiString(lesson.sharingEndDateTime)
                    : undefined,
                sharingToken: lesson.sharingToken,
                schoolYearName: lesson.course.schoolYear.name,
                startDate: getDateFromApiString(lesson.startDateTime),
                subjectName: lesson.course.subject.name,
            },
        };
    }
    return { ...rest, data: undefined };
};
export const getLessonMutationOptions = (lesson) => ({
    variables: {
        input: lesson,
    },
});
export { useUpdateLessonMutation } from 'api/generated';
export const getRemoveObjectsFromLessonMutationOptions = (lessonId, objectIds) => ({
    variables: {
        lessonId,
        objectIds,
    },
    refetchQueries: [ExtendedObjectsDocument],
});
export { useRemoveObjectsFromLessonMutation } from 'api/generated';
export const getAddObjectsToLessonMutationOptions = (lessonId, objectIds) => ({
    variables: {
        lessonId,
        objectIds,
    },
    refetchQueries: [ExtendedObjectsDocument],
});
export { useAddObjectsToLessonMutation } from 'api/generated';
export const getUpdateObjectLessonsMutationOptions = (objectId, lessonIds) => {
    return {
        variables: {
            input: {
                id: objectId,
                lessonIds,
            },
        },
        refetchQueries: [ExtendedObjectsDocument],
    };
};
export { useUpdateObjectLessonsMutation } from 'api/generated';
export const getMoveObjectsToAnotherLessonMutationOptions = (oldLessonId, newLessonId, objects) => {
    objects = Array.isArray(objects) ? objects : [objects];
    const objectIds = objects.map((obj) => obj.id);
    return {
        variables: {
            oldLessonId,
            newLessonId,
            objectIds,
        },
        refetchQueries: [ExtendedObjectsDocument],
    };
};
