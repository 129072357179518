import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import api from 'public/locales/de/api.json';
import auth from 'public/locales/de/auth.json';
import colors from 'public/locales/de/colors.json';
import common from 'public/locales/de/common.json';
import data from 'public/locales/de/data.json';
import home from 'public/locales/de/home.json';
import kratos from 'public/locales/de/kratos.json';
import lesson from 'public/locales/de/lesson.json';
import object from 'public/locales/de/object.json';
import pageNotFound from 'public/locales/de/404.json';
import releases from 'public/locales/de/releases.json';
import settings from 'public/locales/de/settings.json';
import sidebar from 'public/locales/de/sidebar.json';
import termination from 'public/locales/de/termination.json';
import timetable from 'public/locales/de/timetable.json';
void i18n.use(initReactI18next).init({
    cleanCode: true,
    fallbackLng: 'de',
    interpolation: {
        // Not needed for React as it escapes by default.
        escapeValue: false,
    },
    load: 'languageOnly',
    lowerCaseLng: true,
    ns: [
        '404',
        'api',
        'auth',
        'colors',
        'common',
        'data',
        'home',
        'kratos',
        'lesson',
        'object',
        'releases',
        'settings',
        'sidebar',
        'termination',
        'timetable',
    ],
    react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        useSuspense: false,
    },
    resources: {
        de: {
            '404': pageNotFound,
            api,
            auth,
            colors,
            common,
            data,
            home,
            kratos,
            lesson,
            object,
            releases,
            settings,
            sidebar,
            termination,
            timetable,
        },
    },
});
export default i18n;
