import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalendarToday, CalendarViewWeek, Inventory, } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SKIP, useCoursesQueryApi, useLessonsQueryApi, useSchoolYearsQueryApi, } from 'api';
import { FilterBase, LoadingWrapper, Select } from 'components/custom';
import { difference } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { findMostRelevantTimePeriod, formatDateTime } from 'utils/date';
import { getSelectOptions } from 'utils/input';
const LessonFilters = ({ lessonFilter, lessonsList, onClearClick, onLessonOptionClick, setLessonFilter, }) => {
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.LessonFilters',
    });
    const [schoolYear, setSchoolYear] = React.useState();
    const [courseId, setCourseId] = React.useState();
    const setActualSchoolYear = (schoolYears) => {
        setSchoolYear(findMostRelevantTimePeriod(schoolYears));
    };
    const schoolYearsStatus = useSchoolYearsQueryApi({
        onCompleted: setActualSchoolYear,
    });
    const coursesStatus = useCoursesQueryApi((schoolYear === null || schoolYear === void 0 ? void 0 : schoolYear.id) ? { schoolYearId: schoolYear.id } : SKIP);
    const lessonsStatus = useLessonsQueryApi((schoolYear === null || schoolYear === void 0 ? void 0 : schoolYear.startDate)
        ? { from: schoolYear.startDate, to: schoolYear.endDate }
        : SKIP);
    const onSchoolYearChange = (e) => {
        const targetSchoolYear = schoolYearsStatus.data.find((sy) => sy.id === e.target.value);
        setSchoolYear(targetSchoolYear);
        setCourseId('');
    };
    const getCourseLessons = (id) => lessonsStatus.data
        .filter((l) => l.course.id === (id !== null && id !== void 0 ? id : courseId))
        .map((l) => ({
        ...l,
        day: formatDateTime(l.startDate, true),
    }));
    const onCourseChange = (e) => {
        setCourseId(e.target.value);
        const newLessonsFilter = [];
        getCourseLessons(e.target.value).forEach((courseLesson) => {
            if (lessonsList.find((objectLesson) => objectLesson.id === courseLesson.id)) {
                newLessonsFilter.push(courseLesson.id);
            }
        });
        setLessonFilter(newLessonsFilter);
    };
    const getLessonOptions = () => {
        if (!courseId) {
            return [];
        }
        return getSelectOptions(getCourseLessons(), 'day', 'id');
    };
    const onLessonFilterChange = (e) => {
        if ('value' in e.target && Array.isArray(e.target.value)) {
            const [addedLessonId] = difference(e.target.value, lessonFilter);
            const [removedLessonId] = difference(lessonFilter, e.target.value);
            const clickedLesson = lessonsStatus.data.find((lesson) => lesson.id === (addedLessonId !== null && addedLessonId !== void 0 ? addedLessonId : removedLessonId));
            if (clickedLesson) {
                onLessonOptionClick(clickedLesson, addedLessonId ? 'add' : 'remove');
            }
        }
    };
    const onLessonClear = () => {
        if (courseId) {
            onClearClick(courseId);
        }
    };
    const lessonOptions = getLessonOptions();
    const lessonSelectDisabled = lessonOptions.length < 1;
    return (_jsx(Box, { children: _jsx(LoadingWrapper, { status: [schoolYearsStatus, coursesStatus, lessonsStatus], children: _jsxs(Box, { sx: {
                    boxShadow: (theme) => theme.boxShadow,
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    p: 1,
                }, children: [_jsx(Select, { name: "schoolYear", label: t('schoolYearField.label'), placeholder: t('schoolYearField.placeholder'), onChange: onSchoolYearChange, options: getSelectOptions(schoolYearsStatus.data), value: schoolYear === null || schoolYear === void 0 ? void 0 : schoolYear.id, onClear: () => {
                            setSchoolYear(undefined);
                            setCourseId('');
                            setLessonFilter([]);
                        }, clearButtonAriaLabel: t('schoolYearField.clear'), InputProps: {
                            startAdornment: (_jsx(CalendarToday, { sx: { mr: 1 }, color: "secondary" })),
                        } }), _jsx(Select, { name: "course", disabled: !(schoolYear === null || schoolYear === void 0 ? void 0 : schoolYear.id), label: t('courseField.label'), placeholder: t('courseField.placeholder'), onChange: onCourseChange, options: getSelectOptions(coursesStatus.data), value: courseId, onClear: () => {
                            setCourseId('');
                            setLessonFilter([]);
                        }, clearButtonAriaLabel: t('courseField.clear'), InputProps: {
                            startAdornment: _jsx(Inventory, { sx: { mr: 1 } }),
                        } }), _jsx(FilterBase, { filter: lessonFilter, setter: setLessonFilter, options: lessonOptions, onChange: onLessonFilterChange, onClear: onLessonClear, renderSelect: (props) => (_jsx(Select, { ...props, name: "lesson", disabled: lessonSelectDisabled, label: t('lessonField.label'), placeholder: t('lessonField.placeholder'), clearButtonAriaLabel: t('lessonField.clear'), InputProps: {
                                startAdornment: (_jsx(CalendarViewWeek, { sx: {
                                        mr: 1,
                                        opacity: lessonSelectDisabled ? '0.3' : '1',
                                    }, color: "success" })),
                            } })) })] }) }) }));
};
export default LessonFilters;
