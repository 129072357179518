import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from '@mui/material';
import { gridPageSizeSelector, useGridApiRef } from '@mui/x-data-grid';
import { getAddObjectsToLessonMutationOptions, isLoading, useAddObjectsToLessonMutation, useExtendedFileObjectsQueryApi, } from 'api';
import { DataGrid, Draggable } from 'components/custom';
import { isString } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DragAndDropOperationNames } from 'utils/drag-and-drop';
import { getFileIcon } from 'utils/file-icons';
import { useObjectPaginationModel, useObjectSortModel } from 'utils/object';
const DraggableFileObjectGrid = ({ filter = {}, searchQuery = '', onError, onFileSelectionChange, selectedFileId, selectedRowIndex, }) => {
    const apiRef = useGridApiRef();
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [paginationModel, setPaginationModel] = useObjectPaginationModel();
    const [sortModel, setSortModel] = useObjectSortModel();
    const status = useExtendedFileObjectsQueryApi({
        filter: {
            ...filter,
            ...paginationModel,
            sorting: sortModel,
            textQuery: searchQuery,
        },
    });
    const [addObjectsToLessonMutation] = useAddObjectsToLessonMutation();
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.DraggableFileObjectGrid',
    });
    const { enqueueSnackbar } = useSnackbar();
    const { refetch: refetchQuery, error: queryError } = status;
    React.useEffect(() => {
        void refetchQuery({
            filter: {
                ...filter,
                ...paginationModel,
                sorting: sortModel,
                textQuery: searchQuery,
            },
        });
    }, [searchQuery, filter, refetchQuery, paginationModel, sortModel]);
    React.useEffect(() => {
        onError === null || onError === void 0 ? void 0 : onError(queryError);
    }, [onError, queryError]);
    const getDragSuccessHandler = (objectIds, objectName) => (lessonId, courseName) => {
        void addObjectsToLessonMutation(getAddObjectsToLessonMutationOptions(lessonId, objectIds)).then(() => {
            enqueueSnackbar(t('assignmentSuccessful', { courseName, objectName }), {});
        });
    };
    const rows = status.data;
    React.useEffect(() => {
        if (selectedFileId && rows.length > 0) {
            const rowIndex = rows.findIndex((row) => row.id === selectedFileId);
            const pageSize = gridPageSizeSelector(apiRef);
            if (rowIndex >= 0 && pageSize > 0) {
                apiRef.current.setPage(Math.floor(rowIndex / pageSize));
            }
            if (rowIndex < 0 &&
                typeof selectedRowIndex !== 'undefined' &&
                pageSize > 0) {
                apiRef.current.setPage(Math.floor((selectedRowIndex + 1) / pageSize));
            }
            setRowSelectionModel([selectedFileId]);
        }
    }, [selectedFileId, selectedRowIndex, setRowSelectionModel, rows, apiRef]);
    const columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('fileName'),
            renderCell: (params) => {
                const Icon = getFileIcon(params.row.mimeType);
                const FileNameRow = () => (_jsxs(_Fragment, { children: [_jsx(Icon, {}), _jsx(Box, { title: params.row.name, sx: { ml: 1, overflow: 'hidden', textOverflow: 'ellipsis' }, children: params.row.name })] }));
                return (_jsx(Draggable, { operationName: DragAndDropOperationNames.ASSIGN_OBJECT_TO_LESSON, onDragSuccess: getDragSuccessHandler([params.row.id], params.row.name), Ghost: _jsx(Stack, { sx: { p: 1 }, direction: "row", alignItems: "center", children: _jsx(FileNameRow, {}) }), children: _jsx(FileNameRow, {}) }));
            },
        },
    ];
    const loading = isLoading(status);
    return (_jsx(DataGrid, { apiRef: apiRef, rows: rows, columns: columns, loading: loading, hideFooterSelectedRowCount: true, disableRowSelectionOnClick: false, onRowSelectionModelChange: (newRowSelectionModel) => {
            const newSelectedFileId = newRowSelectionModel.length === 1 && isString(newRowSelectionModel[0])
                ? newRowSelectionModel[0]
                : undefined;
            if (!newSelectedFileId) {
                return;
            }
            setRowSelectionModel(newRowSelectionModel);
            onFileSelectionChange === null || onFileSelectionChange === void 0 ? void 0 : onFileSelectionChange(newSelectedFileId);
        }, rowSelectionModel: rowSelectionModel, onPaginationModelChange: setPaginationModel, paginationMode: "server", onSortModelChange: setSortModel, rowCount: status.totalCount, sortingMode: "server", sx: {
            '& .MuiDataGrid-footerContainer > :empty': {
                display: 'none',
            },
            '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
            },
            '& .MuiTablePagination-toolbar': {
                px: 1,
                py: 0,
            },
        } }));
};
export default DraggableFileObjectGrid;
