import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material/styles';
const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
    '&': {
        fontFamily: 'Dosis',
        outline: 'none',
        padding: theme.spacing(theme.layout.textareaPaddingSpacing),
        resize: 'none',
        flexGrow: 1,
    },
    '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));
export default StyledTextarea;
