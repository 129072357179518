import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from '@mui/material';
import { Drawer as StyledDrawer } from 'components/custom';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Navigation from './Navigation';
import StorageWidget from './StorageWidget';
const NavDrawer = ({ drawerOpen, setDrawerOpen, userId, ...props }) => {
    const { t } = useTranslation('common');
    return (_jsxs(StyledDrawer, { "data-testid": "navDrawer", open: drawerOpen, variant: "permanent", width: (theme) => drawerOpen
            ? theme.layout.openNavDrawerWidth
            : theme.layout.closedNavDrawerWidth, ...props, children: [_jsx(Header, { drawerOpen: drawerOpen, setDrawerOpen: setDrawerOpen }), _jsx(Typography, { color: "text.secondary", variant: drawerOpen ? 'h3' : 'h5', align: "center", mt: 2, mb: 5, sx: { fontWeight: 700 }, children: t('body.appName') }), _jsx(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                }, children: _jsx(Navigation, { drawerOpen: drawerOpen, isLoggedIn: !!userId }) }), userId && (_jsxs(_Fragment, { children: [_jsx(Divider, { variant: "middle" }), _jsx(StorageWidget, { drawerOpen: drawerOpen, userId: userId })] }))] }));
};
export default NavDrawer;
