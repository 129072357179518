import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Button, LoadingWrapper, TimetableDataGrid } from 'components/custom';
import { AddCircle } from '@mui/icons-material';
import React from 'react';
import { useVacationsQueryApi } from 'api';
import { useParams } from 'react-router-dom';
import SaveVacationDialog from './SaveVacationDialog';
import DeleteVacationDialog from './DeleteVacationDialog';
const VacationsSection = () => {
    const { t } = useTranslation('settings', {
        keyPrefix: 'body.timetable.steps.vacation.vacationSection',
    });
    const { schoolYearId = '' } = useParams();
    const [activeVacation, setActiveVacation] = React.useState(null);
    const [vacationToDelete, setVacationToDelete] = React.useState(null);
    const status = useVacationsQueryApi({ schoolYearId });
    return (_jsx(LoadingWrapper, { status: status, children: _jsxs(Stack, { spacing: 2, children: [_jsx(TimetableDataGrid, { "data-testid": "vacations", label: _jsx(Trans, { i18nKey: "title", t: t }), rows: status.data, onEditClick: setActiveVacation, onDeleteClick: setVacationToDelete, hasTimeRange: true, noItemsMessage: t('noItemsMessage') }), _jsx(Button, { "data-testid": "addVacationButton", color: "secondary", startIcon: _jsx(AddCircle, {}), onClick: () => {
                        setActiveVacation({});
                    }, sx: { alignSelf: 'end' }, children: t('addButton') }), _jsx(SaveVacationDialog, { close: () => {
                        setActiveVacation(null);
                    }, activeVacation: activeVacation }), _jsx(DeleteVacationDialog, { vacationToDelete: vacationToDelete, setVacationToDelete: setVacationToDelete })] }) }));
};
export default VacationsSection;
