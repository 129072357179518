import { jsx as _jsx } from "react/jsx-runtime";
import { DeletionConfirmationDialog } from 'components/custom';
import { useTranslation } from 'react-i18next';
import { getWeekDayName } from 'utils/date';
import { log, Severity } from 'utils/logger';
const DeleteSlotDialog = ({ course, onConfirm, onClose, }) => {
    var _a;
    const { t } = useTranslation('settings', {
        keyPrefix: 'body.timetable.steps.lessons.cmp.DeleteSlotDialog',
    });
    return (_jsx(DeletionConfirmationDialog, { open: !!course, onClose: onClose, titleProps: {
            text: t('title'),
        }, cancelButtonProps: {
            onClick: onClose,
        }, confirmButtonProps: {
            children: t('actions.delete'),
            onClick: () => {
                if (course) {
                    onConfirm(course);
                }
                else {
                    log('No course for slot deletion', {
                        dump: course,
                        severity: Severity.ERROR,
                    });
                }
            },
        }, children: t('text', {
            hourInDay: course === null || course === void 0 ? void 0 : course.hourInDay,
            name: course === null || course === void 0 ? void 0 : course.name,
            weekday: getWeekDayName((_a = course === null || course === void 0 ? void 0 : course.weekday) !== null && _a !== void 0 ? _a : 0),
        }) }));
};
export default DeleteSlotDialog;
