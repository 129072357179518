import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { DraggableCourseSlot, Droppable, Link, ResizableCourseSlot, } from 'components/custom';
import { getTextColor } from 'utils/color';
import { DragAndDropOperationNames } from 'utils/drag-and-drop';
import { getLessonUrl } from 'utils/links';
const CourseCell = ({ course, day, hour, onDeleteCourseSuccess, onPlanCourseSuccess, onHourLengthChange, isPlanCourseView = false, linkToLesson = false, emptyNextCell, }) => {
    var _a, _b;
    const textColor = getTextColor(course === null || course === void 0 ? void 0 : course.color);
    const courseBlockSx = {
        color: textColor,
        display: 'grid',
        height: '100%',
        p: 1,
        placeItems: 'center',
        textDecoration: 'none',
        width: '100%',
    };
    const courseNameSx = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    };
    const onSizeChange = (diff) => {
        if (!course) {
            return;
        }
        onHourLengthChange === null || onHourLengthChange === void 0 ? void 0 : onHourLengthChange(diff, course);
    };
    return (_jsxs(Box, { role: "gridcell", title: course === null || course === void 0 ? void 0 : course.name, sx: {
            background: (_a = course === null || course === void 0 ? void 0 : course.color) !== null && _a !== void 0 ? _a : 'none',
            border: 'solid 1px',
            borderColor: 'secondary.light',
            display: 'grid',
            gridColumn: day + 1,
            gridRow: `${hour + 1} / span ${(_b = course === null || course === void 0 ? void 0 : course.lengthHours) !== null && _b !== void 0 ? _b : 1}`,
            overflow: 'hidden',
            placeItems: 'center',
            textAlign: 'center',
        }, children: [course && linkToLesson && (_jsx(Droppable, { droppableId: course.slotId, droppableItemName: course.name, operationName: DragAndDropOperationNames.ASSIGN_OBJECT_TO_LESSON, outlineColor: "white", outlinePosition: "inside", children: _jsx(Link, { href: getLessonUrl(course.slotId), sx: courseBlockSx, children: _jsx(Box, { component: "span", sx: courseNameSx, children: course.name }) }) })), course && onPlanCourseSuccess && !linkToLesson && (_jsx(ResizableCourseSlot, { hoursLength: course.lengthHours, iconColor: textColor, onSizeChange: onSizeChange, emptyNextCell: emptyNextCell, children: _jsx(DraggableCourseSlot, { courseWithSlots: course, onPlanCourseSuccess: onPlanCourseSuccess, onDeleteCourseSuccess: onDeleteCourseSuccess }) })), !course && isPlanCourseView && (_jsx(Droppable, { droppableId: `${day}-${hour}`, operationName: DragAndDropOperationNames.PLAN_COURSE, outlineColor: "primary", outlinePosition: "inside" }))] }));
};
export default CourseCell;
