import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Book, Dashboard, ManageAccounts } from '@mui/icons-material';
import { Divider, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as links from 'utils/links';
import NavigationItem from './NavigationItem';
const Navigation = ({ drawerOpen, isLoggedIn, }) => {
    const { t } = useTranslation('common');
    if (!isLoggedIn) {
        return (_jsx(NavigationItem, { text: t('body.navigation.home'), href: links.HOME, Icon: Dashboard, expanded: drawerOpen }));
    }
    return (_jsxs("nav", { children: [_jsxs(List, { children: [_jsx(NavigationItem, { text: t('body.navigation.home'), href: links.HOME, Icon: Dashboard, expanded: drawerOpen }), _jsx(NavigationItem, { text: t('body.navigation.data'), href: links.DATA, Icon: Book, expanded: drawerOpen })] }), _jsx(Divider, { variant: "middle" }), _jsx(List, { children: _jsx(NavigationItem, { text: t('body.navigation.settings'), href: links.ACCOUNT_DATA, Icon: ManageAccounts, expanded: drawerOpen }) })] }));
};
export default Navigation;
