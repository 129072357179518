import { jsx as _jsx } from "react/jsx-runtime";
import { getDeleteObjectsMutationOptions, getUpdateObjectMutationOptions, isLoading, useDeleteObjectsMutation, useExtendedFileObjectsQueryApi, useOperationErrorHandler, useUpdateObjectMutation, } from 'api';
import { FileObjectDataGrid, FileObjectNameCell, FileObjectTagCell, } from 'components/custom';
import { ActionTypes } from 'components/custom/FileObjectDataGrid';
import { isArray, isDate } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import { formatBytes } from 'utils/format';
import { useObjectPaginationModel, useObjectSortModel } from 'utils/object';
const FileObjectGrid = ({ disableActions, filter = {}, searchQuery = '', onSelectionChange, onError, onNameClick, onDeleted, }) => {
    const translationNamespace = 'object';
    const { t } = useTranslation(translationNamespace, {
        keyPrefix: 'cmp.FileObjectGrid',
    });
    const { enqueueSnackbar } = useSnackbar();
    const operationErrorHandler = useOperationErrorHandler();
    const [updateObject, statusUpdateObject] = useUpdateObjectMutation();
    const [deleteObject, statusDeleteObject] = useDeleteObjectsMutation();
    const [paginationModel, setPaginationModel] = useObjectPaginationModel();
    const [sortModel, setSortModel] = useObjectSortModel();
    const status = useExtendedFileObjectsQueryApi({
        filter: {
            ...filter,
            ...paginationModel,
            sorting: sortModel,
            textQuery: searchQuery,
        },
    });
    const { refetch: refetchQuery, error: queryError } = status;
    React.useEffect(() => {
        void refetchQuery({
            filter: {
                ...filter,
                ...paginationModel,
                sorting: sortModel,
                textQuery: searchQuery,
            },
        });
    }, [searchQuery, filter, refetchQuery, paginationModel, sortModel]);
    React.useEffect(() => {
        onError === null || onError === void 0 ? void 0 : onError(queryError);
    }, [onError, queryError]);
    const actionRef = React.useRef({});
    const onRename = (fileObject) => {
        void updateObject(getUpdateObjectMutationOptions({
            id: fileObject.id,
            name: fileObject.name,
        }));
    };
    const onDelete = (obj, options) => {
        const ids = (isArray(obj) ? obj : [obj]).map((item) => item.id);
        deleteObject(getDeleteObjectsMutationOptions(ids))
            .then(() => {
            options.deselectAll();
            if (isArray(obj)) {
                enqueueSnackbar(t('alerts.deleteMultiple.success'), {
                    variant: 'success',
                });
            }
            else {
                enqueueSnackbar(t('alerts.deleteSingle.success', { name: obj.name }), {
                    variant: 'success',
                });
            }
            onDeleted === null || onDeleted === void 0 ? void 0 : onDeleted(ids);
        })
            .catch((error) => {
            operationErrorHandler(error);
        });
    };
    const columns = [
        {
            field: 'name',
            flex: 0.3,
            headerName: t('column.header.name'),
            minWidth: 100,
            renderCell: (params) => {
                const { id, mimeType, name } = params.row;
                return (_jsx(FileObjectNameCell, { fileId: id, mimeType: mimeType, name: name, onClick: () => {
                        onNameClick === null || onNameClick === void 0 ? void 0 : onNameClick(params.row);
                    } }));
            },
        },
        {
            field: 'sizeInBytes',
            headerName: t('column.header.size'),
            minWidth: 82,
            valueFormatter: ({ value, }) => {
                return formatBytes(value);
            },
        },
        {
            description: t('column.headerTooltip.lastUsage'),
            field: 'lastUsage',
            headerName: t('column.header.lastUsage'),
            minWidth: 84,
            type: 'date',
            valueFormatter: ({ value, }) => {
                return isDate(value) ? formatDate(value) : '-';
            },
        },
        {
            field: 'subjectName',
            headerName: t('column.header.subject'),
            minWidth: 50,
            sortable: false,
            valueFormatter: ({ value, }) => {
                return value ? value : '-';
            },
        },
        {
            field: 'educationalYearName',
            headerName: t('column.header.educationalYear'),
            minWidth: 70,
            sortable: false,
            valueFormatter: ({ value, }) => {
                return value ? value : '-';
            },
        },
        {
            field: 'tags',
            flex: 0.2,
            headerName: t('column.header.tags'),
            renderCell: (params) => (_jsx(FileObjectTagCell, { tags: params.row.tags, onEditClick: () => {
                    var _a, _b;
                    (_b = (_a = actionRef.current).setAction) === null || _b === void 0 ? void 0 : _b.call(_a, {
                        item: params.row,
                        type: ActionTypes.TAG,
                    });
                } })),
            sortable: false,
        },
    ];
    const rows = status.data;
    const loading = isLoading([
        status,
        statusUpdateObject,
        statusDeleteObject,
    ]);
    return (_jsx(FileObjectDataGrid, { actionRef: actionRef, disableActions: disableActions, columns: columns, rows: rows, loading: loading, onDelete: onDelete, onRename: onRename, onOperationError: operationErrorHandler, onSelectionChange: onSelectionChange, onSortModelChange: setSortModel, totalCount: status.totalCount, translationNamespace: translationNamespace, onPaginationModelChange: setPaginationModel }));
};
export default FileObjectGrid;
