import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircle, Edit } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { getCreateHolidayMutationOptions, getUpdateHolidayMutationOptions, isLoading, onValidationError, useCreateHolidayMutation, useSchoolYearQueryApi, useUpdateHolidayMutation, } from 'api';
import { ConfirmationDialog, DatePicker, LoadingWrapper, TextFormItem, } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDialogMode } from 'utils/dialog-form';
import { onSubmitError, yup } from 'utils/forms';
const SaveHolidayDialog = (props) => {
    const { activeHoliday, close } = props;
    const { t } = useTranslation('settings', {
        keyPrefix: 'body.timetable.steps.vacation.cmp.SaveHolidayDialog',
    });
    const id = React.useId();
    const { modeCreate, open } = useDialogMode(activeHoliday);
    const schema = React.useMemo(() => yup.object({
        date: yup.date().label(t('dateField.label')).required(),
        name: yup.string().label(t('nameField.label')).required(),
    }), [t]);
    const { handleSubmit, control, formState: { errors, isSubmitting }, reset, } = useForm({
        resolver: yupResolver(schema),
    });
    React.useEffect(() => {
        reset(activeHoliday !== null && activeHoliday !== void 0 ? activeHoliday : {});
    }, [activeHoliday, reset]);
    const { schoolYearId = '' } = useParams();
    const schoolYearStatus = useSchoolYearQueryApi({ id: schoolYearId });
    const [createHoliday, createHolidaysStatus] = useCreateHolidayMutation();
    const [updateHoliday, updateHolidayStatus] = useUpdateHolidayMutation();
    const mutationStatus = modeCreate
        ? createHolidaysStatus
        : updateHolidayStatus;
    const onSuccess = () => {
        close();
        enqueueSnackbar(t('successMessage'));
    };
    const onSubmit = async (values) => {
        if (activeHoliday === null || activeHoliday === void 0 ? void 0 : activeHoliday.id) {
            return updateHoliday(getUpdateHolidayMutationOptions({
                ...values,
                id: activeHoliday.id,
            }))
                .then(onSuccess)
                .catch(onValidationError);
        }
        return createHoliday(getCreateHolidayMutationOptions({
            ...values,
            schoolYearId,
        }))
            .then(onSuccess)
            .catch(onValidationError);
    };
    const loading = isLoading([
        schoolYearStatus,
        updateHolidayStatus,
        createHolidaysStatus,
    ]);
    return (_jsx(ConfirmationDialog, { open: open, onClose: close, onTransitionExited: () => {
            mutationStatus.reset();
        }, titleProps: {
            Icon: modeCreate ? AddCircle : Edit,
            text: modeCreate ? t('addHolidayTitle') : t('editHolidayTitle'),
        }, cancelButtonProps: {
            onClick: close,
        }, confirmButtonProps: {
            disabled: isSubmitting || loading,
            form: id,
            type: 'submit',
        }, fullWidth: true, children: _jsx(LoadingWrapper, { status: schoolYearStatus, mutation: mutationStatus, children: _jsx("form", { id: id, onSubmit: (e) => {
                    handleSubmit(onSubmit)(e).catch(onSubmitError);
                }, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Controller, { control: control, name: "name", render: ({ field }) => {
                                var _a;
                                return (_jsx(TextFormItem, { ...field, label: t('nameField.label'), placeholder: t('nameField.placeholder'), errorMessage: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }));
                            } }), _jsx(Controller, { control: control, name: "date", render: ({ field }) => {
                                var _a, _b, _c;
                                return (_jsx(DatePicker, { ...field, label: t('dateField.label'), errorMessage: (_a = errors.date) === null || _a === void 0 ? void 0 : _a.message, minDate: (_b = schoolYearStatus.data) === null || _b === void 0 ? void 0 : _b.startDate, maxDate: (_c = schoolYearStatus.data) === null || _c === void 0 ? void 0 : _c.endDate }));
                            } })] }) }) }) }));
};
export default SaveHolidayDialog;
