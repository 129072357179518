import { has, isObject } from 'lodash-es';
/**
 * Checks if traits contain user data.
 * @param traits Session identity traits.
 * @returns `true` for user traits; otherwise `false`.
 */
export const hasUserTraits = (traits) => {
    return isObject(traits) && has(traits, 'name');
};
/**
 * Formats user's name.
 * @param user User object.
 * @returns User's name.
 */
export const formatUserName = (user) => {
    const { first, last } = user.name;
    return `${first} ${last}`;
};
