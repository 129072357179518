import { jsx as _jsx } from "react/jsx-runtime";
import { getDeleteSchoolYearMutationOptions, isLoading, useDeleteSchoolYearMutation, } from 'api';
import { DeletionConfirmationDialog, LoadingWrapper } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const DeleteSchoolYearDialog = (props) => {
    const { schoolYearToDelete, setSchoolYearToDelete } = props;
    const { t } = useTranslation('settings', {
        keyPrefix: 'body.timetable.steps.schoolYears.cmp.DeleteSchoolYearDialog',
    });
    const [deleteSchoolYear, statusDeleteSchoolYear] = useDeleteSchoolYearMutation();
    const handleDelete = () => {
        if (schoolYearToDelete === null || schoolYearToDelete === void 0 ? void 0 : schoolYearToDelete.id) {
            void deleteSchoolYear(getDeleteSchoolYearMutationOptions(schoolYearToDelete.id)).then(() => {
                setSchoolYearToDelete(null);
                enqueueSnackbar(t('successMessage'));
            });
        }
    };
    const handleClose = () => {
        setSchoolYearToDelete(null);
        statusDeleteSchoolYear.reset();
    };
    return (_jsx(DeletionConfirmationDialog, { open: !!(schoolYearToDelete === null || schoolYearToDelete === void 0 ? void 0 : schoolYearToDelete.id), onClose: handleClose, titleProps: {
            text: t('title'),
        }, cancelButtonProps: {
            onClick: handleClose,
        }, confirmButtonProps: {
            children: t('actions.delete'),
            disabled: isLoading(statusDeleteSchoolYear),
            onClick: handleDelete,
        }, children: _jsx(LoadingWrapper, { mutation: statusDeleteSchoolYear, children: t('text', {
                name: schoolYearToDelete === null || schoolYearToDelete === void 0 ? void 0 : schoolYearToDelete.name,
            }) }) }));
};
export default DeleteSchoolYearDialog;
