import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExpandMore } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, ListItemText, MenuItem, TextField, Typography, } from '@mui/material';
import { ClearIconButton } from 'components/custom';
import React from 'react';
const CLEAR_BUTTON_RIGHT_POSITION = 2.5;
const Select = (props, ref) => {
    const { clearButtonAriaLabel, errorMessage, InputProps, StartAdornmentIcon, value, options = [], onChange, onClear, renderOption, SelectProps, ...cmpProps } = props;
    const isError = !!errorMessage;
    const id = React.useId();
    const errorId = React.useId();
    const onChangeWrap = onChange
        ? (event) => {
            onChange(event);
        }
        : undefined;
    return (_jsxs(FormControl, { disabled: cmpProps.disabled, variant: "outlined", size: "small", fullWidth: true, error: isError, children: [_jsx(FormLabel, { id: id, children: cmpProps.label }), _jsx(TextField, { color: isError ? 'error' : 'primary', ref: ref, size: "small", InputProps: {
                    'aria-describedby': errorId,
                    startAdornment: StartAdornmentIcon && (_jsx(StartAdornmentIcon, { sx: { mr: 1 } })),
                    sx: {
                        px: 1,
                        ...InputProps === null || InputProps === void 0 ? void 0 : InputProps.sx,
                    },
                    ...(onClear
                        ? {
                            endAdornment: (_jsx(ClearIconButton, { clearButtonAriaLabel: clearButtonAriaLabel, clearButtonRightPosition: CLEAR_BUTTON_RIGHT_POSITION, disabled: cmpProps.disabled, onClick: onClear })),
                        }
                        : {}),
                    ...InputProps,
                }, SelectProps: {
                    IconComponent: ExpandMore,
                    MenuProps: {
                        MenuListProps: { sx: { pb: 0 } },
                        disableAutoFocusItem: true,
                    },
                    displayEmpty: true,
                    labelId: id,
                    onChange: onChangeWrap,
                    ...SelectProps,
                    ...((typeof value === 'string' && value) ||
                        (typeof value === 'number' && value !== 0) ||
                        (Array.isArray(value) && value.length > 0)
                        ? {}
                        : {
                            renderValue: () => (
                            // Opacity 0.42 is used by mui to style the placeholder in text inputs
                            _jsx(Typography, { sx: { opacity: 0.42 }, children: cmpProps.placeholder })),
                        }),
                }, select: true, variant: "outlined", value: value !== null && value !== void 0 ? value : '', ...cmpProps, label: "", children: options.map((o) => (_jsx(MenuItem, { value: o.value, children: renderOption ? (renderOption(o)) : (_jsx(ListItemText, { primary: o.label, primaryTypographyProps: {
                            sx: {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        }, sx: { m: 0 } })) }, o.value))) }), _jsx(FormHelperText, { id: errorId, error: isError, children: errorMessage })] }));
};
export default React.forwardRef(Select);
